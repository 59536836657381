import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import { styled } from 'linaria/react';
import React from 'react';
import { useQuery } from 'react-apollo';
import { startPageEditorComponents } from '../ContentEditorComponents/ContentEditorComponents';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import { theme } from '../Theme';
import startPageQuery from './StartPageQuery.gql';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: hidden;
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId }
  });
  const { loading } = result;
  if (loading || !result?.data) return <LoadingPage />;
  return (
    <StartPageWrapper>
      <StartPageRenderer
        result={result}
        startPageComponents={startPageEditorComponents}
        LoadingPage={LoadingPage}
      />
    </StartPageWrapper>
  );
};

export default StartPage;
